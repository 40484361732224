import React from 'react'
import pcp_et from "../assets/icons/pcp_et.jpg"
import irrigated_land from "../assets/icons/irrigated_land.jpg"
import evapotranspiration from "../assets/icons/evapotranspiration.jpg"
import precipitation_icon from "../assets/icons/precipitation_icon.jpg"
import green_water from "../assets/icons/green_water.jpg"
import biomass from "../assets/icons/biomass.jpg"
import water_consumption from "../assets/icons/water_consumption.jpg"
import crop_land from "../assets/icons/crop_land.jpg"
import area_icon from "../assets/icons/area_icon.jpg"
import blue_water from "../assets/icons/blue_water.jpg"
import { Link } from 'react-router-dom'
import { calculateAverageOfArray, getSumAnnualDataFromMonthly } from '../helpers/functions'



const OverviewSection = ({
    SelectedLandCoverStats,
    hydroclimaticStats,
    croplandHydroclimaticStats
}) => {



    let totalWeightedAETI = 0;
    let totalWeightedPCP = 0;
    let totalWeightedNPP = 0;
    let totalArea = 0;


    hydroclimaticStats.forEach(item => {
        totalWeightedAETI += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.AETI)) * 0.001 * item.AREA;
        totalWeightedPCP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.PCP)) * 0.001 * item.AREA;
        totalWeightedNPP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.NPP)) * 0.001 * item.AREA;
        totalArea += item.AREA;
    });

    let croplandTotalWeightedAETI = 0;
    let croplandTotalWeightedPCP = 0;
    let croplandTotalWeightedNPP = 0;
    let croplandTotalWeightedETB = 0;
    let croplandTotalArea = 0;

    //  Cropland Area is in ha
    croplandHydroclimaticStats.forEach(item => {
        croplandTotalWeightedAETI += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.AETI)) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalWeightedPCP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.PCP)) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalWeightedNPP += calculateAverageOfArray(getSumAnnualDataFromMonthly(item.NPP)) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalWeightedETB += calculateAverageOfArray(item.ETB) * 0.001 * item.Cropland_Area * 10000;
        croplandTotalArea += item.Cropland_Area * 10000;
    });








    const croplandPercentage = ((croplandTotalArea * 100) / (totalArea)).toFixed(1)
    const totalIrrigatedLand = SelectedLandCoverStats.AFG_Landcover[4]




    // EvapotranspirationValue in BCM = ET (mm) * 0.001 * Area (m2)/1000000000
    const TotalETa_BCM = (totalWeightedAETI / 1000000000).toFixed(2)
    const TotalETa_mm = (totalWeightedAETI * 1000 / totalArea).toFixed(2)
    const TotalAreaValue = (totalArea * 0.0000001).toFixed(0)
    const TotalIrrigatedLandValue = (totalIrrigatedLand * 0.001).toFixed(0)
    const TotalIrrigatedPercentage = (totalIrrigatedLand * 10000 * 100 / totalArea).toFixed(1)

    const TotalPCP_BCM = (totalWeightedPCP / 1000000000).toFixed(2)
    const TotalPCP_mm = (totalWeightedPCP * 1000 / totalArea).toFixed(2)
    const TotalPCP_ETa_BCM = ((totalWeightedPCP - totalWeightedAETI) / 1000000000).toFixed(2)
    const TotalPCP_ETa_mm = ((totalWeightedPCP - totalWeightedAETI) * 1000 / totalArea).toFixed(2)
    const TBP_BCM = (totalWeightedNPP * 22.222 * 0.0001 / 1000000).toFixed(2)
    const TBP_ha = (totalWeightedNPP * 22.222 * 1000 / totalArea).toFixed(2)




    const CroplandETa_BCM = (croplandTotalWeightedAETI / 1000000000).toFixed(2)
    const CroplandETa_mm = (croplandTotalWeightedAETI * 1000 / totalArea).toFixed(2)
    const CroplandAreaValue = (croplandTotalArea * 0.0000001).toFixed(0)
    const CroplandPCP_BCM = (croplandTotalWeightedPCP / 1000000000).toFixed(2)
    const CroplandPCP_mm = (croplandTotalWeightedPCP * 1000 / totalArea).toFixed(2)
    const CroplandWaterConsumption = (croplandTotalWeightedAETI / (totalArea * 0.0001)).toFixed(2)
    const CroplandPCP_ETa_BCM = ((croplandTotalWeightedPCP - croplandTotalWeightedAETI) / 1000000000).toFixed(2)
    const CroplandPCP_ETa_mm = ((croplandTotalWeightedPCP - croplandTotalWeightedAETI) * 1000 / totalArea).toFixed(2)
    const CroplandTBP_t = (croplandTotalWeightedNPP * 0.0001 * 22.222 / 1000000).toFixed(2)
    const CroplandTBP_ha = (croplandTotalWeightedNPP  * 22.222 * 1000/  totalArea).toFixed(2)
    const CroplandETb_BCM = (croplandTotalWeightedETB / 1000000000).toFixed(2)
    const CroplandETb_mm = (croplandTotalWeightedETB * 1000 / totalArea).toFixed(2)
    const CroplandETg_BCM = (CroplandETa_BCM - CroplandETb_BCM).toFixed(2)
    const CroplandETg_mm = (CroplandETa_mm - CroplandETb_mm).toFixed(2)



    return (
        <>
            <div className='card_container' >
                <div className='card_heading_container'>
                    <div className='card_heading'>
                        <h4>Total area Stats</h4>
                    </div>
                </div>

                <div className='row'>



                    <div className='col-md-2 col-sm-6 col-6 mb-2'>

                        <div className='overview_icons'>
                            <img src={area_icon} alt='icons' />
                        </div>


                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total area</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalAreaValue).toLocaleString()}</span> (1000 ha)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/et">
                            <div className='overview_icons'>
                                <img src={evapotranspiration} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total evapotranspiration (ET)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalETa_BCM).toLocaleString()}</span> (BCM/year)
                                <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {TotalETa_mm}</span> (mm/year)
                            </p>



                        </div>
                    </div>





                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={precipitation_icon} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total precipitation(PCP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalPCP_BCM).toLocaleString()}</span> (BCM/year)
                                <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {TotalPCP_mm}</span> (mm/year)
                            </p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>

                        <div className='overview_icons'>
                            <img src={water_consumption} alt='icons' />
                        </div>


                    </div>
                    {/* <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Unit water consumption</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(WaterConsumption).toLocaleString()}</span> (m³/ha)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={pcp_et} alt='icons' />
                            </div>
                        </Link>

                    </div> */}
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total Water yield (PCP-ET)</h5>
                            <p>
                                {TotalPCP_ETa_BCM < 0 ? (
                                    <>
                                        <span style={{ fontSize: "24px", color: "red" }}>{TotalPCP_ETa_BCM}</span> (BCM/year)
                                        <br />
                                        <span style={{ fontSize: "24px", color: "red" }}>   {TotalPCP_ETa_mm}</span> (mm/year)
                                    </>


                                ) : (
                                    <>
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{TotalPCP_ETa_BCM}</span> (BCM/year)
                                        <br />
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {TotalPCP_ETa_mm}</span> (mm/year)
                                    </>

                                )}
                            </p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <div className='overview_icons'>
                            <Link to="/bp">
                                <img src={biomass} alt='icons' />
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total biomass production (TBP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{TBP_BCM}</span> (million tons/year)
                                <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {TBP_ha}</span> (kg/ha)
                            </p>
                        </div>
                    </div>







                </div>
            </div>

            <div className='card_container'>
                <div className='card_heading_container'>
                    <div className='card_heading'>
                        <h4>Cropland area Stats</h4>
                    </div>
                </div>

                <div className='row'>


                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/lcc">
                            <div className='overview_icons'>
                                <img src={crop_land} alt='icons' />
                            </div>
                        </Link>


                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            {/* From Esa world cover starts */}
                            <h5>Total cropped land</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandAreaValue).toLocaleString()}</span> (1000 ha) <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {croplandPercentage}</span> (%)</p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/lcc">
                            <div className='overview_icons'>
                                <img src={irrigated_land} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Total irrigated land</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(TotalIrrigatedLandValue).toLocaleString()}</span>  (1000 ha)<br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {TotalIrrigatedPercentage}</span> (%)



                            </p>
                        </div>
                    </div>


                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/et">
                            <div className='overview_icons'>
                                <img src={evapotranspiration} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland evapotranspiration (ET)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandETa_BCM).toLocaleString()}</span> (BCM/year)
                                <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {CroplandETa_mm}</span> (mm/year)
                            </p>
                        </div>
                    </div>





                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={precipitation_icon} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland precipitation(PCP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{parseFloat(CroplandPCP_BCM).toLocaleString()}</span> (BCM/year)
                                <br />
                                <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {CroplandPCP_mm}</span> (mm/year)
                            </p>
                        </div>
                    </div>



                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/pcp">
                            <div className='overview_icons'>
                                <img src={pcp_et} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland Water yield (PCP-ET)</h5>
                            <p>
                                {CroplandPCP_ETa_BCM < 0 ? (
                                    <>
                                        <span style={{ fontSize: "24px", color: "red" }}>{CroplandPCP_ETa_BCM}</span> (BCM/year)
                                        <br />
                                        <span style={{ fontSize: "24px", color: "red" }}>   {CroplandPCP_ETa_mm}</span> (mm/year)
                                    </>


                                ) : (
                                    <>
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandPCP_ETa_BCM}</span> (BCM/year)
                                        <br />
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {CroplandPCP_ETa_mm}</span> (mm/year)
                                    </>

                                )}
                            </p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <div className='overview_icons'>
                            <Link to="/bp">
                                <img src={biomass} alt='icons' />
                            </Link>
                        </div>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland biomass production (TBP)</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandTBP_t}</span> (million tons/year)
                            <br />
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {CroplandTBP_ha}</span> (kg/ha)
                                        </p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/wf">
                            <div className='overview_icons'>
                                <img src={blue_water} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>
                            Cropland blue ET</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandETb_BCM}</span> (BCM/year)
                            <br />
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {CroplandETb_mm}</span> (mm/year)
                                        </p>
                        </div>
                    </div>

                    <div className='col-md-2 col-sm-6 col-6 mb-2'>
                        <Link to="/wf">
                            <div className='overview_icons'>
                                <img src={green_water} alt='icons' />
                            </div>
                        </Link>

                    </div>
                    <div className='col-md-4 col-sm-6 col-6 mb-2'>
                        <div className='overview_decsription'>
                            <h5>Cropland green ET</h5>
                            <p><span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>{CroplandETg_BCM}</span> (BCM/year)
                            <br />
                                        <span style={{ fontSize: "24px", color: "rgb(5, 45, 131)" }}>   {CroplandETg_mm}</span> (mm/year)
                                        </p>
                        </div>
                    </div>





                </div>
            </div>

        </>

    )
}

export default OverviewSection